import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, MenuItem, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledDatePicker,
    ControlledSelectOrganisations,
    ControlledSelectSupportTypes,
    FormGrid,
    Options,
    OptionsToggle,
    SelectComponent,
    useSelectedOrganisation,
    useSupportTypes,
} from '../../../shared';
import { TimeGranularity } from '../../enums';
import { IFilter } from '../../models';

interface Props {
    filter: IFilter;
    onChange: (filter: IFilter) => void;
    showOrganisations?: boolean;
    showSupportTypes?: boolean;
}

export const OldReportFilter: FC<Props> = ({ filter, onChange, showOrganisations = true, showSupportTypes = true }) => {
    const { t } = useTranslation();
    const { organisations: organisationOptions } = useSelectedOrganisation(); //organisations that I have access to
    const supportTypeOptions = useSupportTypes();

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        endDate: { active: false, label: t('endDate') },
        organisations: {
            active: false,
            label: t('organisations'),
            hide: !showOrganisations || organisationOptions.length < 2,
        },
        supportTypes: {
            active: false,
            label: t('supportTypes'),
            hide: !showSupportTypes || supportTypeOptions.length < 2,
        },
        options: { type: 'title', label: t('options') },
        grouping: { active: false, label: t('grouping') },
    });
    useEffect(() => {
        setOptions({
            ...options,
            organisations: { ...options.organisations, hide: !showOrganisations || organisationOptions.length < 2 },
        });
    }, [options, organisationOptions, setOptions]);
    useEffect(() => {
        setOptions({
            ...options,
            supportTypes: { ...options.supportTypes, hide: !showSupportTypes || supportTypeOptions.length < 2 },
        });
    }, [options, supportTypeOptions, setOptions]);

    const form = useForm<IFilter>({ mode: 'onChange' });
    const formValues = form.watch();

    useEffect(() => {
        form.reset({ ...formValues, ...filter });
    }, [form, filter]);

    useEffect(() => {
        if (organisationOptions || supportTypeOptions) {
            const organisationValues = organisationOptions?.map(({ id }) => id);
            form.setValue('organisations', organisationValues);

            const supportTypeValues = supportTypeOptions?.map(({ id }) => id as string);
            form.setValue('supportTypes', supportTypeValues);

            onChange({ ...filter, supportTypes: supportTypeValues, organisations: organisationValues, enabled: true });
        }
    }, [organisationOptions, supportTypeOptions, form.setValue]);

    useEffect(() => {
        const { endDate, organisations, supportTypes, grouping } = options;

        if (!endDate.active) form.setValue('endDate', undefined);

        if (!organisations.active) form.setValue('organisations', organisationOptions?.map(({ id }) => id) || []);

        if (!supportTypes.active)
            form.setValue('supportTypes', supportTypeOptions?.map(({ id }) => id as string) || []);

        if (!grouping.active) form.setValue('grouping', undefined);
    }, [options, form]);

    const updatedOptions =
        JSON.stringify(formValues, Object.keys(formValues).sort()) !==
        JSON.stringify(filter, Object.keys(formValues).sort());

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <OptionsToggle options={options} onChange={setOptions} />

            <FormGrid containerProps={{ spacing: 1, py: 1 }} sx={{ '&.MuiGrid-item': { pr: 1 } }}>
                <ControlledDatePicker name="startDate" label={t('startDate')} control={form.control} size="small" />
                {options.endDate.active && (
                    <ControlledDatePicker name="endDate" label={t('endDate')} control={form.control} size="small" />
                )}
                {options.organisations.active && (
                    <ControlledSelectOrganisations name="organisations" label={t('organisations')} form={form} />
                )}
                {options.supportTypes.active && (
                    <ControlledSelectSupportTypes name="supportTypes" label={t('supportTypes')} form={form} />
                )}
                {options.grouping.active && (
                    <SelectComponent control={form.control} name="grouping" label={t('grouping')} size="small">
                        <MenuItem value="">{t('none')}</MenuItem>
                        {Object.values(TimeGranularity).map((granularity) => (
                            <MenuItem value={granularity} key={granularity}>
                                {t(granularity)}
                            </MenuItem>
                        ))}
                    </SelectComponent>
                )}
            </FormGrid>

            {updatedOptions && (
                <IconButton onClick={() => onChange(formValues)}>
                    <RefreshOutlined color="primary" />
                </IconButton>
            )}
        </Stack>
    );
};
