import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    ReportNewSupportCases,
    ReportRegistrations,
    ReportRegistrationSupportCaseStatus,
    ReportRegistrationSupporteeStatus,
} from './pages';
import { ReportPage } from './pages/report/report.page';
import { ReportsPage } from './pages/reports/reports.page';

export const reportsRoutes: IRouteConfig[] = [
    { path: '/reports', redirect: '/reports/registrations' },
    { path: '/reports/*', component: ReportsPage },
];

export const reportSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/supportee-status', component: ReportRegistrationSupporteeStatus },
    { path: '/support-case-status', component: ReportRegistrationSupportCaseStatus },
    { path: '/new-support-cases', component: ReportNewSupportCases },
    { path: '/registrations', component: ReportRegistrations, requiredPermissions: PermissionKeys.REGISTRATIONS_READ },
    { path: '/:id', component: ReportPage },
];
