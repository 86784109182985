import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../shared';
import { IReportResult } from '../models';
import { IRunReportFilter } from '../models';

async function runReport(id: string, filters: IRunReportFilter[]): Promise<IReportResult> {
    const { data } = await axios.post(`/reporting/reports/${id}/run`, {
        filters,
    });
    return data;
}

export const useReportResult = (id: string, filters: IRunReportFilter[]) => {
    return useQuery<IReportResult, AxiosError>({
        queryKey: [queryKeys.REPORT_RESULT, id, filters],
        queryFn: () => runReport(id, filters),
    });
};
